
import EventPictureModule from "@/components/modules/EventPictureModule.vue";
import CreationMadeBy from "@/components/events/CreationMadeBy.vue";
import ThumbnailList from "@/components/events/ThumbnailList.vue";
import HallOfFameColumn from "@/components/events/HallOfFameColumn.vue";
import { PROPERTIES } from "@/data/properties";
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  components: {
    CreationMadeBy,
    EventPictureModule,
    HallOfFameColumn,
    ThumbnailList,
  },
  setup() {
    const route = useRoute();
    const key = computed(() => Number(route.params["id"]));
    const prop = computed(() => PROPERTIES.get(key.value));
    const thumbnails = computed(() => prop.value ? (prop.value.images || []) : []  )
    return { key, prop, thumbnails };
  },
});
