
//import { CharacterKey } from "@/data/characters";
import { defineComponent } from "vue";

/*interface MadeByProps {
  user: CharacterKey;
}*/

export default defineComponent({
  props: ["user"],
  setup() {},
});
