<template>
  <div class="column-round" id="CreationMadeBy">
    <b><i></i></b><i><b></b></i><em></em>
    <div class="column-round-body">
      <p>
        <nexus-link :user="user"></nexus-link>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#CreationMadeBy {
  margin-bottom: 14px;
}
</style>

<script lang="ts">
//import { CharacterKey } from "@/data/characters";
import { defineComponent } from "vue";

/*interface MadeByProps {
  user: CharacterKey;
}*/

export default defineComponent({
  props: ["user"],
  setup() {},
});
</script>
