<template>
  <div class="column column-small last">
    <div class="column-top">
      <h1>Hall of Fame</h1>
    </div>
    <div class="column-content">
      <p>Latest entries</p>

      <div class="t-list rounded-small small flow">
        <div class="t-row" v-for="prop in props" :key="prop.id" style="height: 45px; padding: 6px 4px;">
          <b><i></i></b><i><b></b></i><em></em>
          <div class="t-cell">
            <h3>
              <router-link
                :to="{ name: 'community-property', params: { id: prop.id } }"
                >{{ prop.name }}</router-link
              >
            </h3>
            <p>Property of the week {{ prop.week }}</p>
          </div>
        </div>
      </div>
      <p class="last">
        <router-link :to="{ name: 'community-halloffame' }" class="linkArrow"
          >View Hall of Fame</router-link
        >
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { getProps } from "@/data/properties";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    return { props: getProps([354051, 353336, 352498, 351289, 350382]) };
  },
});
</script>
