
import { Image } from "@/data/properties";
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: ["images"],
  setup(props: {images: Image[]}) {
    const images = computed(() => props.images);
    return { images };
  },
});
