import { getUser } from "@/data/universe";
import { CharacterKey } from "./characters";

export interface ID {
    id: number;
}

export interface Property {
    name: string,
    description: string,
    detail?: string,
    week: string,
    user: CharacterKey,
    images: Image[],
};

export class Image {
    url?: string;
    preview?: string;
    constructor(url: string, preview: string) {
        this.url = url;
        this.preview = preview;
    }
};

const PROPERTIES: Map<number, Property & ID> = new Map();
function addProp(id: number, prop: Property) {
    PROPERTIES.set(id, Object.assign(prop, { id }));
}

export function ingameEventImage(pic: string): string {
    return `https://clc.archive.lu-dev.net/upload/contentTemplating/UniverseInGameEventsImages/images/${pic}`;
}

addProp(347037, {
    name: "Avant Conservatory",
    description: "A unique conservatory is being developed by Hollis out in Avant Grove. Hollis's highly creative building techniques make exploring every corner of this tower a delight. Dr. Overbuild is anxious to see its completion.",
    detail: `There is nothing like the Avant Conservatory in all of LEGO Universe. Inside this monolithic structure that reaches toward the sky you will find the Giant-Shuner Wood. On the first floor be sure to stop by the Tarbucks for some LEGO coffee. Wrapped through the tree is a staircase that takes you to the second floor. Hopefully Hollis will finish his creation soon.`,
    images: [
        {
            url: ingameEventImage("pic37035F14F98F6E34517F89119F1C0C4C.jpg"),
            preview: ingameEventImage("pic2A7549AD2E99D24D027CF8BA3FFEB6A3.jpg"),
        },
        {
            url: ingameEventImage("pic5206AB6AD554B54DAE66019A253AFCB4.jpg"),
            preview: ingameEventImage("pic6495718D5D09EFC6A34362F9181C6D38.jpg"),
        },
        {
            url: ingameEventImage("picAD6795457E83B6C7A3ECE65F37FC67CD.jpg"),
            preview: ingameEventImage("pic419EBB57040DDFB5D76DBDB674E5BB10.jpg"),
        }
    ],
    user: getUser(150, "hollis"),
    week: "8/16/2011",
});

addProp(354712, {
    name: "Retro Diner",
    description: `Take a blast to the past! Rocket off to Chantey Shanty to visit Ikeboy's Retro Diner!`,
    week: "9/29/2011",
    user: getUser(150, "ikeboy"),
    images: [
        {
            url: ingameEventImage("pic806D2A2A09D8435961B8433123ABF81B.jpg"),
            preview: '',
        }
    ]
});

addProp(354051, {
    name: "Forest Home",
    description: `Rocket off to Nimbus Rock today to see Ace15's Forest Home! Take a break from
         smashing Skeletons and come on down to Forest Home to enjoy nature at it's finest.`,
    week: "9/23/2011",
    user: getUser(150, "ace15"),
    images: [
        {
            url: ingameEventImage("picC8408CD59E27D70C4D2CC92A083C27D1.png"),
            preview: '',
        }
    ]
});
addProp(353336, {
    week: "9/16/2011",
    name: "Wild West",
    description: `Howdy, partner! Grab your cowboy hat and head on down to FoxMcCloud's Wild West property,
    located in Avant Grove.`,
    detail: `Howdy, partner! Grab your cowboy hat and head on down to FoxMcCloud's Wild West property,
        located in Avant Grove. An accurate throwback to the old west, this impressive property is
        just good old fashioned fun! Hang out in the saloon or save the
        bank from those good for nothin' scoundrels! Bring a friend and
        just have a good time, letting your inner cowboy/cowgirl out!
        Yeeeee-haw!`,
    user: getUser(150, "foxmccloud"),
    images: [
        {
            url: ingameEventImage("pic4AFBB3CE959CC9DEA09F340A12258070.jpg"),
            preview: ingameEventImage("pic7EAFCADDC2416349A4A81C2E4266E9E6.jpg"),
        },
        {
            url: ingameEventImage("picEB6A5DC9424C1E83E84A57749FFB158D.jpg"),
            preview: ingameEventImage("pic1FBFA227CCFA6B43DAD82561E18B76FD.jpg"),
        },
        {
            url: ingameEventImage("picC89ACF5A345DB384C6A16FBA09D73265.jpg"),
            preview: ingameEventImage("picC31F13F782072C72711EE2B0D9BA1BE6.jpg"),
        },
    ],
});
addProp(352498, {
    week: "9/9/2011",
    images: [
        {
            url: ingameEventImage("pic2D7D724A0F000140997712561ABF56EB.jpg"),
            preview: '',
        }
    ],
    name: "attack at nimbus capital",
    description: `Fly over to Nimbus Isle as quick as you can to see the "attack at
        nimbus capital" by michaelvortex!`,
    user: getUser(150, "michaelvortex"),
});
addProp(351289, {
    user: getUser(150, "enigmaticplastic"),
    name: "Kingdoms for Sale",
    description: `
        EnigmaticPlastic has been hard at work on Nimbus Isle. Fuel up your
        rocket and get ready to see some of the largest models you can find in
        LEGO Universe.`,
    // Fixme: is the entire detail
    detail: `It seems that EnigmaticPlastic is out to create the seven wonders of the LEGO Universe.
        You can see the Taj Mahal, Big Ben, the Statue of Liberty and even more. EnigmaticPlastic
        truly has a mastery of the LEGO Universe build system.`,
    week: "9/1/2011",
    images: [
        {
            url: ingameEventImage("pic7164B52392B7DDDBBEAD19DC8BE535D1.jpg"),
            preview: ingameEventImage("pic619AB1EB29CD80AD9129A79CF1888532.jpg"),
        },
        {
            url: ingameEventImage("pic4E8845D9105D418DD01F5D5167917CCF.jpg"),
            preview: ingameEventImage("pic188186D34B319F597656C190BC33885B.jpg"),
        },
        {
            url: ingameEventImage("picF9FAFE89CBDBEFB425C1904AFD3E5BB1.jpg"),
            preview: ingameEventImage("pic91528AAB25B2B4704913B52C176EDE42.jpg"),
        }
    ]
});
addProp(350382, {
    images: [
        {
            url: ingameEventImage("picCCF46D63433E903C17E7AE1456FF2DA1.jpg"),
            preview: ''
        }
    ],
    user: getUser(150, "samuraimaster"),
    name: "Galaxy Space Dock",
    description: `Deep in the darkest spacey corner of LEGO Universe you will find the
          Galaxy Space Dock. Jump on your rocket and head for this moon crusted
          planetoid on Avant Grove. SamariMaster has his latest creation on
          display for all to explore.`,
    week: "8/29/2011",
});

export function getProps(props: number[]): (Property & ID)[] {
    let result: (Property & ID)[] = [];
    props.forEach(i => {
        let p = PROPERTIES.get(i);
        if (p) {
            result.push(p);
        }
    });
    return result;
}

export { PROPERTIES };