<template>
  <div class="column-round" id="ThumbnailList">
    <b><i></i></b><i><b></b></i><em></em>
    <div class="column-round-body">
      <h3>More screenshots:</h3>
      <ul id="EventScreenshotThumbnails" class="eventThumbnails">
        <li v-for="(image, index) in images" :key="index">
          <a :href="image.url"
            ><img :src="image.preview || image.url" width="128" height="95" class="rounded"
          /></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { Image } from "@/data/properties";
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: ["images"],
  setup(props: {images: Image[]}) {
    const images = computed(() => props.images);
    return { images };
  },
});
</script>


<style lang="scss" scoped>
#ThumbnailList {
  margin-bottom: 14px;
}

#EventScreenshotThumbnails {
  margin: 0 -7px 14px -7px;
  padding: 0;
  list-style-type: none;
  overflow: hidden;
}

#EventScreenshotThumbnails li {
  float: left;
  margin: 0 7px 14px 8px;
}

#EventScreenshotThumbnails a {
  display: block;
}

.eventThumbnails {
  margin: 7px -7px -14px -7px;
  padding: 0;
  list-style-type: none;
  overflow: hidden;
}
</style>