<template>
  <div>
    <event-picture-module
      :img="prop.images[0].url"
      h2="CREATED BY"
      :h3="prop.user.name"
    ></event-picture-module>
    <!--<text-module title="Property of the Week">
      {{ $route.params.id }}
    </text-module>-->
    <!-- FIXME: auto discover column rows -->
    <div class="column-row column-row-2">
      <div class="column column-medium first">
        <div class="column-top">
          <h1>Property of the week</h1>
        </div>
        <div class="column-content">
          <creation-made-by :user="prop.user"></creation-made-by>
          <thumbnail-list :images="prop.images"></thumbnail-list>

          <div class="column-round">
            <b><i></i></b><i><b></b></i><em></em>
            <div class="column-round-body">
              <h3>{{ prop.name }}</h3>
              <p>{{ prop.detail || prop.description }}</p>
            </div>
          </div>
        </div>
      </div>

      <hall-of-fame-column></hall-of-fame-column>
    </div>
  </div>
</template>

<script lang="ts">
import EventPictureModule from "@/components/modules/EventPictureModule.vue";
import CreationMadeBy from "@/components/events/CreationMadeBy.vue";
import ThumbnailList from "@/components/events/ThumbnailList.vue";
import HallOfFameColumn from "@/components/events/HallOfFameColumn.vue";
import { PROPERTIES } from "@/data/properties";
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  components: {
    CreationMadeBy,
    EventPictureModule,
    HallOfFameColumn,
    ThumbnailList,
  },
  setup() {
    const route = useRoute();
    const key = computed(() => Number(route.params["id"]));
    const prop = computed(() => PROPERTIES.get(key.value));
    const thumbnails = computed(() => prop.value ? (prop.value.images || []) : []  )
    return { key, prop, thumbnails };
  },
});
</script>
