<template>
  <text-module>
    <div class="negative-row" id="EventPicture">
      <div id="EventPictureBg">
        <img
          id="ctl00_ContentPlaceHolderUniverse_imageBig"
          class="rounded"
          :src="img"
          height="596"
          width="677"
        />
      </div>
      <div id="EventCreatedBy" class="column-round transparent">
        <b><i></i></b><i><b></b></i><em></em>
        <div class="column-round-body">
          <h2>{{ h2 }}</h2>
          <h3>{{ h3 }}</h3>
        </div>
      </div>
      <div id="EventBadge"></div>
    </div>
  </text-module>
</template>

<style lang="scss" scoped>
#EventPicture {
  position: relative;
}
#EventCreatedBy {
  position: absolute;
  top: 14px;
  right: 14px;
  h2,
  h3 {
    text-align: right;
    padding: 0px;
    font-size: 300%;
    margin: 0;
  }
}
</style>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: ["h2", "h3", "img"],
  setup() {},
});
</script>
